// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import route from '../../routes';
import type { FrontMatter, ImageType } from '../../utils/types';
import CanyonRatingCard from '../../components/canyon-rating-card';
import { withFrontMatter } from '../../components';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8337: ImageType,
    _DSF8340: ImageType,
    _DSF8348: ImageType,
    _DSF8359: ImageType,
    _DSF8367: ImageType,
    _DSF8375: ImageType,
    _DSF8385: ImageType,
    _DSF8392: ImageType,
    _DSF8421: ImageType,
    _DSF8427: ImageType,
    _DSF8430_panorama: ImageType,
    _DSF8452: ImageType,
    _DSF8461: ImageType,
  },
};

class Blog_2018_09_20_Mystery_Revisited extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <CanyonRatingCard title="Mystery Canyon" rating="3BIII" />
        <p>
          Day 1 of the Zion Canyon Rendezvous started with an invitation to do
          Mystery. I had been wanting to do this canyon under different
          circumstances than{' '}
          <Link to={route.blog_2014_09_20_rockfall_in_mystery_canyon}>
            last time
          </Link>
          .
        </p>
        <p>
          We set up a shuttle and picked up permits in the morning and then
          headed off to the Observation Point trailhead.
        </p>
        <h2>Approach</h2>
        <Image image={data._DSF8337} caption="At the trailhead ready to go" />
        <Image image={data._DSF8340} caption="Top of Mystery Canyon" />
        <p>
          We arrived at the top and made our way down Death Gulley. The ranger
          told us to <GlossaryLink>rappel</GlossaryLink> all the "optional"
          rappels at the beginning. It is possible to walk around these but
          creates unsightly social trails. We had a beginner in our group so
          doing these shorter rappels was good practice for him.
        </p>
        <h2>Technical Section</h2>
        <Image
          image={data._DSF8348}
          caption={'Coming down one of the "optional" rappels'}
        />
        <p>
          We soon entered the first <GlossaryLink>narrows</GlossaryLink> and{' '}
          <GlossaryLink id="sequence-rappels">
            sequenced the rappels
          </GlossaryLink>{' '}
          in quick succession.
        </p>
        <Image image={data._DSF8359} />
        <Image image={data._DSF8367} />
        <p>
          After the rappels and the big left turn towards the Zion Narrows we
          encountered a few fun <GlossaryLink>down climbs</GlossaryLink> and
          then broke for lunch at the dry lake.
        </p>
        <Image
          image={data._DSF8375}
          caption="Giving pointers on how to down climb safely"
        />
        <p>
          As we were approaching the lake I pointed out where water was the last
          time. I remember seeing a swamp-like area with an ever-deepening pool
          that ended in a 100' swim. The group was very surprised that the lake
          could see that much water.
        </p>
        <Image image={data._DSF8385} caption="The lake completely dry" />
        <Image
          image={data._DSF8392}
          caption="View of the rest of the canyon from the top of the dam"
        />
        <h2>Rockfall area</h2>
        <p>
          The last time I descended Mystery we experienced a significant
          rockfall right before the Mystery Springs rappel. I wanted to get
          pictures of the area and point out where some of the events took
          place. I have updated the{' '}
          <Link to={route.blog_2014_09_20_rockfall_in_mystery_canyon}>
            Rockfall in Mystery Canyon
          </Link>{' '}
          blog post with pictures if you haven't seen them already.
        </p>
        <h2>Mystery Springs</h2>
        <p>
          I wish I could capture how impressive this area and rappel are. You
          are in a narrow section hiking and down climbing when suddenly you
          round a corner and the earth disappears. You look down and can't see
          anything for a good hundred or so feet.
        </p>
        <Image
          image={data._DSF8421}
          caption="Atop the Mystery Springs rappel"
        />
        <p>
          Below you it looks like you will rappel into an empty pit and climb
          back up to a tall sand dune. Looking closer, though, you realize that
          you are looking into a crystal clear pool that only makes itself known
          through the tiniest ripples. This is truly the soul of Mystery Canyon.
          And you must look deep down to see it.
        </p>
        <p>
          Or you could just throw the rope down and hear a splash instead of
          thump. That would also give it away.
        </p>
        <Image
          image={data._DSF8427}
          caption="Looking down into Mystery Springs"
        />
        <p>
          Descending the canyon to this point was completely dry. Now it was
          time to get wet. Very cold but a few strokes later and I was out again
          and feeling refreshed.
        </p>
        <h2>Final Rappel</h2>
        <p>
          The final rappel. The reason this canyon is so sought after. It drains
          into the Zion Narrows within a mile of the Temple of Sinawava and is
          densely populated with hikers looking up at the spectacular waterfall.
          And we get to rappel down into it!
        </p>
        <Image
          image={data._DSF8430_panorama}
          caption="Looking down into the Zion Narrows"
        />
        <Image image={data._DSF8452} caption="Rappelling down" />
        <p>
          And just like that, we were done with the technical section! We
          de-rigged, packed up, and headed to the shuttle buses and dinner.
        </p>
        <Image image={data._DSF8461} caption="Mostly group shot at the end" />{' '}
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-09-20-mystery-revisited')(
  Blog_2018_09_20_Mystery_Revisited
);

export const query = graphql`
  query {
    _DSF8337: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8337.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8340: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8340.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8348: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8348.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8359: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8359.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8367: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8367.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8375: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8375.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8385: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8385.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8392: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8392.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8421: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8421.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8427: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8427.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8430_panorama: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8430_panorama.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8452: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8452.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8461: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8461.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
